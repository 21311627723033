import React, { useState, useRef } from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Grow, Slide } from '@material-ui/core';

// REDUX
import { useSelector } from 'react-redux';

// Components
import Category from './Category';
import CategoryCard from './CategoryCard';
import Skeleton from '@material-ui/lab/Skeleton';

// Images
import agriculture from '../../images/Categories/agriculture.webp';
import arts from '../../images/Categories/art-theatre-music.webp';
import category from '../../images/Categories/category.webp';
import economics from '../../images/Categories/economics.webp';
import engineering from '../../images/Categories/engineering.webp';
import general from '../../images/Categories/general.webp';
import healthcare from '../../images/Categories/healthcare.webp';
import history from '../../images/Categories/history.webp';
import language from '../../images/Categories/language.webp';
import science from '../../images/Categories/nautral-sciences.webp';
import psychology from '../../images/Categories/psychology.webp';
import region from '../../images/Categories/region.webp';
import school from '../../images/Categories/school.webp';
import law from '../../images/Categories/social-sciences-law.webp';
import tourism from '../../images/Categories/tourism.webp';
import transport from '../../images/Categories/transport.webp';

//Logos
import arcada from '../../images/Logos/arcada_logo_black.webp';
import helsinki from '../../images/Logos/university-of-helsinki-logo.webp';
import konstuniversitetet from '../../images/Logos/konstuniversitetetUniversityofArts.webp';
import aalto from '../../images/Logos/aalto.webp';
import abo from '../../images/Logos/AboAkademi-logo_black.webp';
import aland from '../../images/Logos/Hogskolanpaaland-alt.webp';
import novia from '../../images/Logos/Novia.webp';
import hanken from '../../images/Logos/HankenSvenskaHandelshogskolan.webp';

const kategorierImages = [
  { general },
  { transport },
  { tourism },
  { economics },
  { history },
  { arts },
  { law },
  { agriculture },
  { science },
  { language },
  { engineering },
  { psychology },
  { healthcare },
];

const Categories = ({ refetch, isLoading }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [openCategory, setOpenCategory] = useState('');
  const { regionOptions, amnen, studieort } = useSelector((state) => state.filters);

  const setImg = (data) => {
    let item = kategorierImages.find((el) => data.toLowerCase().includes(Object.keys(el).join(' ')));
    if (item === undefined) {
      return general;
    }
    return Object.values(item).toString();
  };
  const amnenSubjects = amnen?.map((item) => Object.assign(item, { image: setImg(item[1].nimi.en) }));
  const studieortSubjects = studieort?.map((item) => Object.assign(item, { image: history }));

  const scrollRef = useRef(null);

  let regions = regionOptions?.map((re) => re.value);
  const studieorterCategory = [];
  regions.forEach((region, index) => {
    let regionObj = { id: index, title: region, image: history };
    studieorterCategory.push(regionObj);
  });

  const handleClick = (title) => {
    !isLoading && setChecked(true);
    setOpenCategory(title);
    executeScroll();
  };

  const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const CategoryOptions = (activeCategory) => {
    const category = activeCategory.activeCategory;

    if (category === 'KATEGORIER') {
      return (
        <div onClick={() => setChecked((prev) => !prev)}>
          <CategoryCard subjects={amnenSubjects} category={category} refetch={refetch} />
        </div>
      );
    }
    if (category === 'HÖGSKOLOR') {
      return (
        <div onClick={() => setChecked((prev) => !prev)}>
          <CategoryCard subjects={högskolorCategory} category={category} refetch={refetch} />
        </div>
      );
    }
    if (category === 'STUDIEORTER') {
      return (
        <div onClick={() => setChecked((prev) => !prev)}>
          <CategoryCard subjects={studieortSubjects} category={category} refetch={refetch} />
        </div>
      );
    }
    return <div></div>;
  };

  return (
    <div className={classes.root}>
      <div className={classes.cards}>
        {isLoading ? [...Array(3)]?.map((e, i) => <Skeleton key={i} className={classes.skeletonCard} />) : ''}
        {subjects?.map((sub) => (
          <Slide key={sub.id} direction='up' in={!isLoading} mountOnEnter unmountOnExit>
            <div>
              <Category subject={sub} onClick={() => handleClick(sub.title)} />
            </div>
          </Slide>
        ))}
      </div>
      <Collapse timeout={10} in={checked} className={classes.collapse} ref={scrollRef}>
        <div>
          <Grow in={checked}>
            <div>
              <CategoryOptions activeCategory={openCategory} />
            </div>
          </Grow>
        </div>
      </Collapse>
    </div>
  );
};

export default Categories;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem auto',
    maxWidth: 1000,
    minWidth: 1000,
    display: 'flex',
    flexBasis: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
  },
  cards: {
    marginTop: '7rem',
    marginBottom: '5rem',
    minWidth: '100%',
    margin: '1rem auto',
    display: 'flex',
    flexBasis: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  skeletonCard: {
    maxWidth: 245,
    minWidth: 245,
    height: 216,
    transform: 'unset',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '85%',
      minWidth: '85%',
      margin: '1rem auto',
    },
  },
}));

const subjects = [
  {
    id: 1,
    title: 'KATEGORIER',
    image: category,
  },
  {
    id: 2,
    title: 'HÖGSKOLOR',
    image: school,
  },
  {
    id: 3,
    title: 'STUDIEORTER',
    image: region,
  },
];

const högskolorCategory = [
  {
    id: 1,
    title: 'Aalto-universitetet',
    location: 'Esbo',
    image: aalto,
  },
  {
    id: 2,
    title: 'Yrkeshögskolan Novia',
    location: 'Jakobstad, Ekenäs',
    image: novia,
  },
  {
    id: 3,
    title: 'Åbo Akademi',
    location: 'Åbo',
    image: abo,
  },
  {
    id: 4,
    title: 'Helsingfors universitet',
    location: 'Helsingfors',
    image: helsinki,
  },
  {
    id: 5,
    title: 'Yrkeshögskolan Arcada',
    location: 'Helsingfors',
    image: arcada,
  },
  {
    id: 6,
    title: 'Konstuniversitetet',
    location: 'Helsingfors',
    image: konstuniversitetet,
  },
  {
    id: 7,
    title: 'Högskolan på Åland',
    location: 'Mariehamn',
    image: aland,
  },
  {
    id: 8,
    title: 'Hanken Svenska Handelshögskolan',
    location: 'Helsingfors, Vasa',
    image: hanken,
  },
];
